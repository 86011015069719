function ErrorReloginRequired() {

    return (
        <div>
        <h4>Session expired</h4>
        <p>Your session has expired.  Please logout and log back in.</p>
      </div>
    )

}

export default ErrorReloginRequired