import { useState } from "react";
import { fetchNetworkOptions } from '../NetworkUtils';
import ErrorReloginRequired from "./ErrorRelogin";

function SearchForm({ nextPage }) {

    const [query, setstate] = useState({
        query: '',
        results: []
    });

    const [availableAssets, setAvailableAssets] = useState([]);

    const [selectedDevice, setSelectedDevice] = useState({
        id: "",
        issueType: "",
        other: ""
    });

    const [isError, setIsError] = useState(0);

    const handleChange = (e) => {

        //Ignore searching for first few characters
        if (e.target.value.length > 2) {
            const found = availableAssets.filter(asset => {
                return asset.sitename.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()) || asset.devicename.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase());
            }
            );
            setstate(
                {
                    query: e.target.value,
                    results: found
                }
            )
        } else {
            setstate(
                {
                    query: '',
                    retults: []
                }
            )
        }
    }

    const canMoveNext = () => {
        return (selectedDevice.id !== undefined && selectedDevice.id !== '' && selectedDevice.issueType !== undefined && selectedDevice.issueType !== '');
    }

    const issueOptions = [
        {
            id: "offline",
            text: "Router offline",
        },
        {
            id: "speed",
            text: "Slow speeds",
        },
        {
            id: "wifi",
            text: "Cannot connect to WiFi",
        },
        {
            id: "wifi_no_inet",
            text: "Connected to WiFi without internet access"
        },
        {
            id: "power",
            text: "Power Issue",
        },
        {
            id: "other",
            text: "Other",
        },
    ];

    const errorBody = (
        <div class="row">
            <div class="col">
                <div class="card">
                    <h5 class="card-header">Please choose your site</h5>
                    <div class="card-body">
                        <p>Error received trying to get a list of sites.</p>
                        <p>If this persists, please contact Blackspot Networks.</p>
                    </div>
                </div>
            </div>
        </div>
    );

    const api_server = process.env.REACT_APP_BASE_API_DOMAIN + "/api/assets";

    if (availableAssets === undefined || availableAssets.length === 0) {
        fetch(api_server, {
            headers: fetchNetworkOptions(),
        })
            .then(response => {
                if (response.ok) {
                    return response.json() ;
                } else {
                    throw new Error(response.status);
                }
            })
            .then(data => {
                setAvailableAssets(data);
            })
            .catch(error => {
                setIsError(error.message);
                return false
            });
    }

    const mainBody = (
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <h5 class="card-header">Please choose your site</h5>
                        <div class="card-body">
                            <div class="input-group mb-3">
                                <span class="input-group-text" id="basic-addon1">Search</span>
                                <input type="text"
                                    class="form-control"
                                    placeholder="Site name"
                                    aria-label="sitename"
                                    aria-describedby="basic-addon1"
                                    //value={query.query}
                                    onChange={handleChange}
                                ></input>
                            </div>
                            <ul>
                                {
                                    query.results && query.results.map(result =>
                                        <div class="form-check" key={result.devicename}>
                                            <input class="form-check-input" type="radio" name="availableDevice" id={result.devicename} onChange={(e) => setSelectedDevice({
                                                id: result.devicename,
                                                issueType: selectedDevice.issueType,
                                                other: selectedDevice.other
                                            })}></input>
                                            <label class="form-check-label">
                                                {result.devicename} - {result.sitename}
                                            </label>
                                        </div>
                                    )
                                }
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="col">
                    <div class="card">
                        <h5 class="card-header">Please choose issue classification</h5>
                        <div class="card-body">
                            <div class="container">
                                {
                                    issueOptions.map(op =>
                                        <div class="form-check" key={op.id}>
                                            <input class="form-check-input" type="radio" name="issueClassification" id={op.id} key={op.id} onChange={(e) => setSelectedDevice({
                                                id: selectedDevice.id,
                                                issueType: e.target.id,
                                                other: selectedDevice.other
                                            })}></input>
                                            <label class="form-check-label">
                                                {op.text}
                                            </label>
                                        </div>
                                    )
                                }
                                <input type="text"
                                    class="form-control"
                                    aria-label="other"
                                    aria-describedby="basic-addon1"
                                    onChange={(e) => setSelectedDevice({
                                        id: selectedDevice.id,
                                        issueType: selectedDevice.issueType,
                                        other: e.target.value
                                    })}></input>
                            </div>
                            <p></p>
                            <button class="btn active float-end" disabled={!canMoveNext()} onClick={(e) => canMoveNext() && nextPage(selectedDevice)}  >Next</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                </div>
                <div class="col">
                    <div class="container">
                    </div>
                </div>
            </div>
        </div>
    );

    return (<>
        {(isError === 401) ? <ErrorReloginRequired></ErrorReloginRequired> : (isError === 0) ? mainBody : errorBody }
    </>
    );

}

export default SearchForm