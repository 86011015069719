// 1. Manage store/retreive of JWT token
// 2. Return headers for function call

const TOKEN_KEY = 'jwt-token'

function fetchTokenFromStorage() {
    return localStorage.getItem(TOKEN_KEY);    
}

export const resetTokenKey = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const fetchNetworkOptions = () => {
    // Fetch the current JWT token or no token available

    const token = fetchTokenFromStorage();
    const headers = {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + token, 
    }

    return headers;
};