import React, { useState } from "react";
import { fetchNetworkOptions } from "../NetworkUtils";

function LoadData({ incidentId, device, gotData }) {

  const [isError, setIsError] = useState(false);

  //ADD THE AUTH SETTINGS
  const api_server = process.env.REACT_APP_BASE_API_DOMAIN + "/health/check?device=" + device.id;

  console.log(api_server);
  device['snow'] = incidentId;

  fetch(api_server,  {
    method: "POST",
    headers: fetchNetworkOptions(),
    body: JSON.stringify(device)
  })
    .then(response => {
      if (response.ok) {
        return response.json() ;
      } else if (response.status === 401) {
        throw new Error("Unauthorized, token expired?");
      } else {
        throw new Error("Unknown status");
      }
      }).then(data => {
      gotData(data);
    })
    .catch(error => {
      console.log("Exception thrown");
      setIsError(true);
      return false;
    });

  const errorBody = (
    <div>
      <h4>Failed to run tests</h4>
      <p>If this continues please contact Blackspot Networks</p>
    </div>
  );

  const proggressBody = (
    <><p></p>
      <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }}></div>
      </div>
      <p></p>
      <p>Please note speedtest results will update on the following page after it has loaded due to the length of time it takes for it to run.</p>
    </>);

  return (
    <div class="row">
      <div class="col"></div>
      <div class="col">
        <div class="card">
          <h6 class="card-header">Running router checks</h6>

          <div class="card-body">
            {isError ? errorBody : proggressBody}
          </div>
        </div>

      </div>
      <div class="col"></div>
    </div>)

}

export default LoadData