import StateAndDescription from './ItemStatusFunc';
import { fetchNetworkOptions } from '../NetworkUtils';
import { useRef, useEffect, useState } from "react";


function SpeedTestResult({ sp, retries }) {
    if (retries < 1) {
        return (<>Download: {sp.speedtest.dl} Upload: {sp.speedtest.ul}</>);
    } else {
        return <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar progress-bar-striped progress-bar-animated" style={{ width: '100%' }}></div>
      </div>
    }
}

function TextDetail({ data, speedtest }) {

    const [copyEnabled, setCopyEnabled] = useState(false);

    const handleChange = (e) => {
        setCopyEnabled(e.target.checked);
    };

    const generateTextString = () => {
        return "Your Blackspot Incident ID is: " + data.incidentId + "\n" +
                                            "---------------------------\n" + 
                                            "Router Incident Summary\n" +
                                            "---------------------------\n" + 
                                            "\n" +
                                            data.sitename + "\n\n" +
                                            "Site ID:" + data.devicename + "\n" +
                                            "Site postcode: " + data.postcode + "\n" +
                                            "Data last refreshed: " + data.update_time + "\n\n" + 
                                            (data.router_respond_sms ? "[YES]": "[NO]") + " Router responded to SMS\n" +
                                            (data.internet_connection ? "[YES]": "[NO]") + " Router Connected to Internet\n" +
                                            (data.ee_postcode_issue ? "[YES]": "[NO]") + " EE Network Status issue (Site Postcode)\n" +                                    
                                            (data.last_cell_status === "bad" ? "[YES] EE Network Status issue (Last known mast) \n": "") +
                                            ((data.ee_postcode_issue || data.last_cell_status === 'bad') ? "|       +++   Possible EE Network Issue  +++         |\n\n" : "\n") +
                                            (data.internet_connection ? "Last connected cell: " + data.cell_id + "\n": "") +
                                            
                                            (speedtest.speedtest.dl !== "no_data" ? "[YES]": "[NO]") + " Current Speed Test ::  " + speedtest.speedtest.dl + " / " + speedtest.speedtest.ul + "\n\n" +
                                            "[YES] + Router uptime (since 10pm reboot):: " + (data.uptime / 60).toFixed(2) +" minutes\n" +
                                            "[YES] + Users connected to WiFi :: " + data.wifi_users.length + "\n\n" +
                                            "Network :: " + data.operator + "\n" +
                                            "Data usage (last 24 hours) :: " + (data.data_usage_24 > 0 ? (data.data_usage_24 / 1024 / 1024 / 1024).toFixed(3) : 0 ) + " GB\n" +
                                            "Data usage (last 30 days) :: " + (data.data_usage_30d > 0 ? (data.data_usage_30d / 1024 / 1024 / 1024).toFixed(3) : 0 ) + " GB\n" +
                                            "\n" +
                                            "Router SIM Number: +44" + data.sim1_no;
    }

    return (
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <h5 class="card-header">Snow ticket</h5>
                        <div class="card-body">
                            <h6>Please copy the below into your SNOW ticket:</h6>
                            <div class="card">
                            <div class="card-body">
                                    <pre className={!copyEnabled ? "block-select" : ""}>{generateTextString()}
                                    </pre>

                                </div>
                            </div>
                            <div class="pt-3"></div>
                            <div class="alert alert-danger pt-2" role="alert">
                                <h5 class="alert-heading">Please confirm the following:</h5>
                                <ul>
                                    <li>1. Ensure no devices are connected to the LAN sockets</li>
                                    <li>2. Power cycle the router</li>
                                    <li>3. Confirm LED status lights (on and blinking, on and solid)</li>
                                </ul>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="defaultCheck1" onChange={handleChange}></input>
                                <label class="form-check-label">If an EE Network issue has been detected, please raise a ticket with EE and do not assign this to Blackspot Networks</label>
                            </div>
                            <div class='pt-2 d-grid gap-2 col-6 mx-auto'>
                            <button class="btn btn-outline position-end" disabled={!copyEnabled} onClick={ (e) => {navigator.clipboard.writeText(generateTextString()) }}>Copy output</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function EEDetail({ data }) {
    const conditionCheck = (e) => {
        return e ? '🟢' : '🔴';
    }

    const conditionCheckCell = (e) => {
        return e === "ok" ? '🟢' : '🔴';
    }

    return <><li class="mt-2"><StateAndDescription icon={conditionCheck(!data.ee_postcode_issue)} description="[Postcode] Network status at device postcode"></StateAndDescription></li>
        {data.ee_postcode_issue ? <li class="mt-2"><div class="alert alert-danger" role="alert">Possible EE outage: Raise an incident with EE corporate service team</div></li> : ""}
        {!(data.last_cell_status === "ok" || data.last_cell_status ===  "bad") ? <li>🟡 [Cell/Mast] Unable to check status for last reported cell id</li> : 
        <li class="mt-2"><StateAndDescription icon={conditionCheckCell(data.last_cell_status)} description="[Cell/Mast] Network status for last reported cell id"></StateAndDescription></li>}
        {data.last_cell_status === "bad" ? <li class="mt-2"><div class="alert alert-danger" role="alert">Possible EE outage: Raise an incident with EE corporate service team</div></li> : ""}
        </>
}

function O2Detail() {

    return <><li class="mt-2"><StateAndDescription icon="🟡" description="EE network status"></StateAndDescription></li>
        <li>
            <div class="alert alert-warning" role="alert">
                O2 Network (Please check manually - click for O2 status checker)
            </div>
        </li>
    </>
}


function VisualDetail({ data, speedtest, retries }) {

    const conditionCheck = (e) => {
        return e ? '🟢' : '🔴';
    }

    return (
        <div class="container">

            <div class="card">
                <h5 class="card-header">{data.devicename} :: Information</h5>
                <div class="card-body">
                    <ul class="list-unstyled">
                    <li><h6>{data.sitename}</h6></li>
                        <li><div class="alert alert-secondary" role="alert">Your Blackspot Incident ID: {data.incidentId}<br></br>Your SNOW Incident ID: {data.snowId}</div></li>
                        <li>Data last refreshed: {data.update_time}</li>
                        <li>Device tel number: (+44){data.sim1_no}</li>
                        <li>Device postcode: {data.postcode}</li>
                        {
                            data.internet_connection ? <li>{"Router uptime since 10PM reboot: " + (data.uptime / 60 / 60).toFixed(2) + " hours"}</li> : ""
                        }
                    </ul>
                </div>
            </div>

            <div class="pt-3">
            <div class="card">
                <h5 class="card-header">Diagnostic Information</h5>
                <div class="card-body">
                    <ul class="list-unstyled">
                        <li class="mt-2"><StateAndDescription icon={conditionCheck(data.router_respond_sms)} description="Router responded to SMS"></StateAndDescription></li>
                        <li class="mt-2"><StateAndDescription icon={conditionCheck(data.internet_connection)} description="Router connected to internet"></StateAndDescription></li>

                        {data.internet_connection ? 
                        <li class="mt-2"><StateAndDescription icon={conditionCheck(speedtest.speedtest.dl !== "no data")} description="Speedtest (Mbps): "></StateAndDescription><SpeedTestResult sp={speedtest} retries={retries}></SpeedTestResult></li>
                        : <></>
                        }
                        <li class="mt-2"><StateAndDescription icon={conditionCheck(data.wifi_users.length > 0)} description={"Devices connected to WiFi: " + data.wifi_users.length}></StateAndDescription></li>
                    </ul>

                    {data.wifi_users.length > 0 ? 
                    <div><h4>Connected Devices</h4>

                    <ul class="list-unstyled">
                        {data.wifi_users.map((e) => {
                            return <li><i class="bi-wifi"></i> {e.mac} - {e.dnsname} - {e.wifidevice}</li>
                        })}
                    </ul></div>
                    : ""
                    }
                    <div>
                        <h4>Network Data Usage :: {data.operator}</h4>
                        <ul class="list-unstyled">
                            <li>Data usage (last 24 Hours) :: {data.data_usage_24 > 0 ? (data.data_usage_24 / 1024 / 1024 / 1024).toFixed(3) : 0} GB</li>
                            <li>Data usage (last 30 Days) :: {data.data_usage_30d > 0 ? (data.data_usage_30d / 1024 / 1024 / 1024).toFixed(3) : 0} GB</li>
                        </ul>
                    </div>

                    <h4>Known Cells</h4>
                    <ul class="list-unstyled">

                        {data.known_cellids.map((e) => {
                            if (e.postcode === undefined || e.postcode === '') return;
                            return <li>{e.cell_id} - {e.postcode}</li>
                        })}
                    </ul>

                </div>

            </div>
            </div>
            <div class="pt-3">
                <div class="card">
                    <h5 class="card-header">Cellular / Mobile status</h5>
                    <div class="card-body">
                        <ul class="list-unstyled">
                            <li>Network ::  {data.operator}</li>
                            <li>Last reported connected cell: {data.cell_id}</li>

                            {(data.operator === "EE EE") || (data.operator === "EE") ? <EEDetail data={data}></EEDetail> : <O2Detail></O2Detail>}


                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getData = async (deviceName, successCallback, failureCallback, errorCallback) => {

    const api_server = process.env.REACT_APP_BASE_API_DOMAIN + "/health/speedtestresult?device=" + deviceName;

    // if (spRef.current.speedtest.dl !== "no data") {
    //     clearInterval(intervalCall.current);
    // }

    try {
        const res = await fetch(api_server, {
            headers: fetchNetworkOptions(),
        });
        const jdata = await res.json();
        if (res.ok) {
            // setState({
            //     speedtest: jdata,
            // });
            // setRetries(0);
            successCallback(jdata);
        } else {
            failureCallback(jdata);
        }
    } catch (e) {
        errorCallback(e);
    }
};


function RouterStatus({ data }) {

    const intervalCall = useRef(null);
    const spRef = useRef(null);

    const [speedtest, setState] = useState({
        speedtest: { "dl": "no data", "ul": "no data" },
    });

    const [speedtestRetries, setStateSpeedtestRetries] = useState(2);

    spRef.current = speedtest;

    useEffect(() => {
        if (data.internet_connection) {
            intervalCall.current = setInterval(() => {

                if (speedtestRetries !== 0) {
                    getData(data.devicename,
                        (jdata) => {
                        //Success
                        if (intervalCall.current) {
                            clearInterval(intervalCall.current);
                            intervalCall.current = null;
                        }
                        setState({
                            speedtest: jdata
                        });

                        setStateSpeedtestRetries(0);

                    }, () => {
                        //Failure
                        // Decrement retries
                        setStateSpeedtestRetries(speedtestRetries - 1);
                    }, () => {
                        //Error
                        if (intervalCall.current) {
                            clearInterval(intervalCall.current);
                            intervalCall.current = null;
                        }
                    });
                } else {
                    if (intervalCall.current) {
                        clearInterval(intervalCall.current);
                        intervalCall.current = null;
                    }
                }
            }, 30000);
        } else {
            intervalCall.current = undefined
        }
        return () => {
            // clean up
            if (intervalCall.current) {
                clearInterval(intervalCall.current);
            }
        };
    }, [data, speedtestRetries]);

    return (
        <div class="container">
            <div class="row">
                <div class="col">
                    <VisualDetail data={data} speedtest={speedtest} retries={speedtestRetries}></VisualDetail>
                </div>
                <div class="col">
                    <TextDetail data={data} speedtest={speedtest}></TextDetail>
                </div>
            </div>
        </div>
    );

}

export default RouterStatus