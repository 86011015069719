import Logo from './img/bs-logo-white.png';
import React, { useState } from "react";
import RouterStatus from './components/RouterStatus';
import SearchForm from './components/SearchForm';
import LoadData from './components/Loading';
import { fetchNetworkOptions, resetTokenKey } from './NetworkUtils';

import './App.css';

function App() {

  const [errorMessages, setErrorMessages] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const api_server = process.env.REACT_APP_BASE_API_DOMAIN + "login";

  const serverLogin = (user, pass) => {

    const details = {
      "username": user,
      "password": pass
    }

    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(details)
    };

    fetch(api_server, options)
      .then(response => response.json())
      .then(data => {
        if ("access_token" in data) {
          console.log("Logged in!");
          //Store token locally
          localStorage.setItem("jwt-token", data.access_token);
          setStep("incident");
        } else {
          console.log("Login error");
          setErrorMessages("login");
          return false;
        }
      })
      .catch(error => { console.error(error); return false });
  };


  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    serverLogin(uname.value, pass.value);
  };

  const loginForm = (
    <div class="container">
      <div class="row">
        <div class='col'>
          <p></p>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">
              BCAT Support Dashboard
            </div>
            <div class="card-body">
              <form onSubmit={handleSubmit}>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="addon-wrapping">Username</span>
                  <input type="text" name="uname" class="form-control" required />
                  {renderErrorMessage("uname")}
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="addon-wrapping">Password</span>
                  <input type="password" name="pass" class="form-control" required />
                  {renderErrorMessage("pass")}
                </div>
                <div class='row'>
                  <div class='col'>
                    <div class='d-grid gap-2 col-6 mx-auto'>
                      <button type="submit" class="btn btn-primary">Sign in</button>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row pt-2">
                <div class="col">
                  {
                    errorMessages === "login" ? <div class="alert alert-danger" role="alert">Login error. Check username and password</div> : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='col'>
          <p></p>
        </div>
      </div>
    </div>
  );

  const incidentIdForm = (
    <div class="container">
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <div class="card">
            <h5 class="card-header">SNOW Incident number</h5>
            <div class='card-body'>
              <div className="form">
                <p>E.g INC1234567</p>
                <div className="input-group mb-3">
                  <input type="text" class="form-control" placeholder="Incident number" name="snow" aria-describedby="button-addon2" onChange={(e) => setIncidentId(e.target.value)} />
                  <button class="btn btn-outline-secondary" id="button-addon2" onClick={() => {
                    if (incidentId != undefined && incidentId.startsWith("INC")) {
                      setStep("describe");
                    }
                  }}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col"></div>
      </div>
    </div>

  )

  const [device, setDevice] = useState({});
  const [fullReport, setFullReport] = useState({});
  const [incidentId, setIncidentId] = useState();


  const steps = {
    login: loginForm,
    incident: incidentIdForm,
    describe: <SearchForm nextPage={(data) => { setDevice(data); setStep('load') }}></SearchForm>,
    load: <LoadData incidentId={incidentId} device={device} gotData={(data) => { data['snowId'] = incidentId; setFullReport(data); setStep('status'); }}></LoadData>,
    status: <RouterStatus data={fullReport}></RouterStatus>
  }

  const [currentStep, setStep] = useState('login');


  return (
    <>
      <nav class="navbar sticky-top bg-body-tertiary" data-bs-theme="dark">
        <div class="container-fluid">
          <a class="navbar-brand" href=""><img src={Logo}></img></a>

          {(currentStep == 'login') ? "" :
            <span class="navbar-text">
              <button class="btn btn-outline" onClick={() => setStep('incident')} >Restart process</button><></>
              <button class="btn btn-outline ms-2" onClick={() => { resetTokenKey(); setStep('login');}}>Logout</button><></>

            </span>
          }

        </div>
      </nav >

      <div className="App" class="container py-4 px-3 mx-auto">

        {(currentStep == 'login') ? "" :
          <div class="row">
            <div class="col">
              {/* <SteppedBar position="1" steps="4"></SteppedBar> */}
            </div>
          </div>
        }
        {steps[currentStep]}
      </div>
    </>
  );
}

export default App;
